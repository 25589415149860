var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.questionTemplate === 'SAT')?_c('div',{staticClass:"question-content",staticStyle:{"margin-top":"10px"}},[_c('div',{staticStyle:{"background":"#dadada","margin-bottom":"12px"}},[_c('kbd',[_vm._v(_vm._s(_vm.getQuestionContent().order))])]),_c('div',{staticStyle:{"padding-left":"28px"}},[_c('b',{ref:"questionContent"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getQuestionContent().content)}})])])]):_c('div',{staticClass:"question-content",staticStyle:{"margin-top":"10px"}},[_c('b',[_vm._v(" "+_vm._s(`${_vm.getQuestionContent().order}.`)+"  "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getQuestionContent().content)}})])]),_c('div',{style:(`padding-left: ${_vm.questionTemplate === 'SAT' ? '28px' : '0px'}`)},[(
        _vm.getQuestionContent().type === 'default' ||
          _vm.getQuestionContent().type === 'click'
      )?_c('div',{ref:"questionContent",staticClass:"ivy-question-options"},[_c('div',{staticClass:"options"},_vm._l((_vm.getQuestionContent().options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',[_c('input',{staticClass:"magic-radio",attrs:{"type":"radio","id":option.id},domProps:{"value":option.value,"checked":option.value === _vm.answers[0]},on:{"input":e => {
                  _vm.setAnswer(e.target.value);
                }}}),_c('label',{attrs:{"for":option.id}},[(option.title.includes('<img'))?_c('div',{staticStyle:{"display":"flex"},domProps:{"innerHTML":_vm._s(
                  `${option.letter}${
                    _vm.questionTemplate === 'SAT' ? ')&nbsp;' : '.&nbsp;'
                  } ${option.title}`
                )}}):_c('p',{domProps:{"innerHTML":_vm._s(
                  `${option.letter}${
                    _vm.questionTemplate === 'SAT' ? ')&nbsp;' : '.&nbsp;'
                  } ${option.title.replace('<p>', '').replace('</p>', '')}`
                )}})])])])}),0)]):(_vm.isMultiple)?_c('ul',{staticClass:"multiple-ivy-question-options"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("practice.answers")))]),_c('draggable',{staticClass:"list-group row",attrs:{"list":_vm.tempMultipleAnswers,"group":"people"},on:{"change":_vm.setAnswer}},_vm._l((_vm.getAnswerInformation(_vm.answers)),function(answer){return _c('div',{key:answer.letter,staticClass:"list-item"},[_vm._v(" "+_vm._s(`${answer.letter}. ${answer.title}`)+" ")])}),0)],1),_c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("practice.options")))]),_c('draggable',{staticClass:"list-group row",attrs:{"list":_vm.filterChose(_vm.getQuestionContent().options),"group":"people"}},_vm._l((_vm.filterChose(
                _vm.getQuestionContent().options
              )),function(option,index){return _c('div',{key:index,staticClass:"list-item"},[_vm._v(" "+_vm._s(`${option.letter}. ${option.title}`)+" ")])}),0)],1)])]):(_vm.isMathCalculator)?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"radioInputForMath"},[_c('div',{staticClass:"inputRow"},_vm._l((Object.keys(_vm.tempMathCalculation)),function(inputIndex){return _c('div',{key:inputIndex,staticClass:"math_input"},[_c('input',{attrs:{"type":"text","maxlength":"1","oninput":"value=value.replace(/[^\\d^\\.\\/]/g,'')"},domProps:{"value":_vm.tempMathCalculation[inputIndex]},on:{"input":({ target: { value } }) => _vm.setTempMathCalculation(inputIndex, value)}})])}),0),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"text-align":"center","padding":"5px 0","display":"flex","flex-direction":"column","justify-content":"space-around"}},_vm._l((['/', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']),function(option){return _c('div',{key:option,staticClass:"math_num"},[_c('span',[_vm._v(_vm._s(option))])])}),0),_vm._l((Object.keys(_vm.tempMathCalculation)),function(inputIndex,inputNumber){return _c('div',{key:inputIndex,staticStyle:{"text-align":"center","padding":"5px 0"}},_vm._l((['/', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']),function(option,optionNumber){return _c('div',{key:option,staticClass:"math_option"},[_c('el-radio',{attrs:{"disabled":inputNumber == 0 && optionNumber == 0,"value":_vm.tempMathCalculation[inputIndex],"label":option},on:{"input":(value) => _vm.setTempMathCalculation(inputIndex, value)}},[_c('span',{class:_vm.tempMathCalculation[inputIndex] === option
                      ? 'answer_active'
                      : ''})])],1)}),0)})],2)])]):_c('div',{staticClass:"checkbox-ivy-question-options"},[_c('el-checkbox-group',{attrs:{"value":_vm.answers},on:{"input":_vm.setAnswer}},_vm._l((_vm.getQuestionContent().options),function(option,index){return _c('el-checkbox',{key:index,staticClass:"option",attrs:{"label":option.value}},[_vm._v(" "+_vm._s(`${option.letter}. ${option.title}`)+" ")])}),1)],1),(_vm.question.question_type === 'toefl')?_c('h5',{staticStyle:{"margin-top":"8px"}},[_vm._v(" The corresponding paragraph number is highlighted. ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }