<template>
  <div>
    <div
      v-if="questionTemplate === 'SAT'"
      class="question-content"
      style="margin-top:10px"
    >
      <div style="background: #dadada; margin-bottom: 12px">
        <kbd>{{ getQuestionContent().order }}</kbd>
      </div>
      <div style="padding-left: 28px">
        <b ref="questionContent">
          <span v-html="getQuestionContent().content"></span>
        </b>
      </div>
    </div>
    <div v-else class="question-content" style="margin-top:10px">
      <b>
        {{ `${getQuestionContent().order}.` }}&nbsp;
        <span v-html="getQuestionContent().content" />
      </b>
    </div>
    <div
      :style="`padding-left: ${questionTemplate === 'SAT' ? '28px' : '0px'}`"
    >
      <div
        ref="questionContent"
        class="ivy-question-options"
        v-if="
          getQuestionContent().type === 'default' ||
            getQuestionContent().type === 'click'
        "
      >
        <div class="options">
          <div
            class="radio"
            v-for="option in getQuestionContent().options"
            :key="option.id"
          >
            <div>
              <input
                class="magic-radio"
                :value="option.value"
                :checked="option.value === answers[0]"
                @input="
                  e => {
                    setAnswer(e.target.value);
                  }
                "
                type="radio"
                :id="option.id"
              />
              <label :for="option.id">
                <div
                  v-if="option.title.includes('<img')"
                  style="display: flex"
                  v-html="
                    `${option.letter}${
                      questionTemplate === 'SAT' ? ')&nbsp;' : '.&nbsp;'
                    } ${option.title}`
                  "
                />
                <p
                  v-else
                  v-html="
                    `${option.letter}${
                      questionTemplate === 'SAT' ? ')&nbsp;' : '.&nbsp;'
                    } ${option.title.replace('<p>', '').replace('</p>', '')}`
                  "
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <ul class="multiple-ivy-question-options" v-else-if="isMultiple">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="text-center">{{ $t("practice.answers") }}</h5>
            <draggable
              class="list-group row"
              :list="tempMultipleAnswers"
              group="people"
              @change="setAnswer"
            >
              <div
                class="list-item"
                v-for="answer in getAnswerInformation(answers)"
                :key="answer.letter"
              >
                {{ `${answer.letter}. ${answer.title}` }}
              </div>
            </draggable>
          </div>
          <div class="col-sm-6">
            <h5 class="text-center">{{ $t("practice.options") }}</h5>
            <draggable
              class="list-group row"
              :list="filterChose(getQuestionContent().options)"
              group="people"
            >
              <div
                class="list-item"
                v-for="(option, index) in filterChose(
                  getQuestionContent().options
                )"
                :key="index"
              >
                {{ `${option.letter}. ${option.title}` }}
              </div>
            </draggable>
          </div>
        </div>
      </ul>
      <div style="display: flex; justify-content: center;" v-else-if="isMathCalculator">
        <div class="radioInputForMath">
          <div class="inputRow">
            <div
              class="math_input"
              v-for="(inputIndex) in Object.keys(tempMathCalculation)"
              :key="inputIndex"
            >
              <input
                type="text"
                maxlength="1"
                :value="tempMathCalculation[inputIndex]"
                @input="({ target: { value } }) => setTempMathCalculation(inputIndex, value)"
                oninput="value=value.replace(/[^\d^\.\/]/g,'')"
              />
            </div>
          </div>
          <div style="display: flex">
            <div style="text-align: center; padding: 5px 0; display: flex; flex-direction: column; justify-content: space-around;">
              <div
                class="math_num"
                v-for="option in ['/', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']"
                :key="option"
              >
                <span>{{ option }}</span>
              </div>
            </div>
            <div
              style="text-align: center;padding: 5px 0;"
              v-for="(inputIndex,inputNumber) in Object.keys(tempMathCalculation)"
              :key="inputIndex"
            >
              <div
                class="math_option"
                v-for="(option,optionNumber) in ['/', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']"
                :key="option"
              >
                <el-radio
                  :disabled="inputNumber == 0 && optionNumber == 0"
                  :value="tempMathCalculation[inputIndex]"
                  @input="(value) => setTempMathCalculation(inputIndex, value)"
                  :label="option"
                >
                  <span
                    :class="
                      tempMathCalculation[inputIndex] === option
                        ? 'answer_active'
                        : ''
                    "
                  ></span>
                </el-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="checkbox-ivy-question-options" v-else>
        <el-checkbox-group :value="answers" @input="setAnswer">
          <el-checkbox
            class="option"
            v-for="(option, index) in getQuestionContent().options"
            :label="option.value"
            :key="index"
          >
            {{ `${option.letter}. ${option.title}` }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <h5 style="margin-top: 8px" v-if="question.question_type === 'toefl'">
        The corresponding paragraph number is highlighted.
      </h5>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import testsApi from "@/apis/tests.js";

export default {
  components: { draggable },
  props: {
    defaultQuestionTemplate: {
      type: String,
      default: "ORIGINAL"
    },
    answers: {
      type: Array,
      default: () => []
    },
    question: {
      type: Object,
      default: () => ({
        id: null,
        order: null,
        content: "",
        options: [],
        tags: [],
        exp: ""
      })
    }
  },
  data() {
    return {
      tempMultipleAnswers: [],
      tempMathCalculation: {
        0: "", 1: "", 2: "", 3: ""
      }
    };
  },
  computed: {
    isMultiple() {
      return this.getQuestionContent().type === "multiple";
    },
    isCheckbox() {
      return this.getQuestionContent().type === "checkbox";
    },
    isMathCalculator() {
      return this.getQuestionContent().type === "math";
    },
    questionTemplate() {
      if (this.defaultQuestionTemplate !== "ORIGINAL") {
        return this.defaultQuestionTemplate;
      } else {
        return this.question.question_type.toUpperCase();
      }
    }
  },
  watch: {
    answers() {
      this.setLocalAnswer();
    }
  },
  created() {
    this.setLocalAnswer();
  },
  mounted() {
    if(this.$mathJax.isMathjaxConfig) {
      this.$mathJax.initMathjaxConfig();
    }
    this.$mathJax.MathQueue([this.$refs.questionContent, this.$refs.optionContent]);
  },
  methods: {
    setLocalAnswer() {
      if (this.isMultiple) {
        this.tempMultipleAnswers = this.getAnswerInformation(this.answers);
      } else if (this.isMathCalculator) {
        this.tempMathCalculation = {
          0: "", 1: "", 2: "", 3: ""
        };
        let answer = "";
        if (this.answers.length !== 0) {
          answer = this.answers[0];
        }
        Object.keys(answer).forEach((inputIndex) => {
          this.tempMathCalculation[inputIndex] = answer[inputIndex];
        });
      }
    },
    setTempMathCalculation(index, value) {
      this.tempMathCalculation[index] = value;
      this.setAnswer(this.tempMathCalculation);
      console.log(this.isMultiple);
    },
    setAnswer(answer) {
      if (this.isMultiple) {
        console.log(this.isMultiple);
        if (this.tempMultipleAnswers.length > 3) {
          this.tempMultipleAnswers.pop();
        }
        this.$emit(
          "setAnswer",
          this.tempMultipleAnswers.map(({ value }) => value)
        );
      } else if (this.isCheckbox) {
        if (answer.length > 2) {
          return;
        }
        this.$emit("setAnswer", [...answer]);
      } else {
        console.log(answer);
        this.$emit("setAnswer", [answer]);
      }
    },
    getAnswerInformation(answers) {
      return answers.map(answer =>
        this.getQuestionContent().options.find(({ value }) => value === answer)
      );
    },
    getQuestionContent() {
      return this.question.question;
    },
    filterChose(options) {
      return options.filter(({ value }) =>
        Boolean(
          !this.tempMultipleAnswers.find(answer => answer.value === value)
        )
      );
    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflTest/style/tpo.css"></style>
<style scoped>
.ivy-tag {
  margin: 0 10px 10px 10px;
}
.question-content {
  font-size: 16px;
  margin-bottom: 20px;
}
.ivy-question-options {
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.multiple-ivy-question-options .option {
  font-size: 16px;
  border-bottom: solid 1px #e5e9ef;
  padding: 10px 0;
  padding-left: 34px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.question_default {
  cursor: pointer;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  display: inline-block;
  background: #ddd;
  margin: 5px;
}

.question_id {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: 3px auto;
  background: #fff;
  text-align: center;
  line-height: 25px;
}

.question_success {
  background: #43a06b;
}
.checkbox-ivy-question-options .option {
  font-size: 16px;
  display: flex;
}

.checkbox-ivy-question-options .option >>> .el-checkbox__label {
  margin-bottom: 30px;
  font-weight: normal;
  color: #000;
  font-size: 16px;
  display: block;
  white-space: pre-wrap;
}

.list-group {
  width: 100%;
  min-height: 300px;
  /* max-height: 400px; */
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
}

.list-item {
  height: 100px;
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px;
}

.radioInputForMath {
  width: 156px
}

.question .option,
.answer_input,
.num,
.math_input {
  margin-right: 2px;
}
.math_input input {
  margin: 4px;
  font-size: 18px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}

.inputRow {
  display: flex;
  justify-content: flex-end;
  padding-right: 2px;
}

.question .option:hover,
.math_option:hover {
  cursor: pointer;
}

.question .option:hover span,
.math_option:hover span {
  background-color: #17a2b8;
  color: white;
  border-color: #17a2b8;
}
.question .option span,
.math_option span {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 21px;
  border: solid 2px #42a16a;
  border-radius: 50%;
  font-weight: 500;
}

.math_num, .math_option {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
}
.answer_input input,
.math_input input {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
.answer_active {
  background-color: #42a16a;
  color: white;
}

.math_option >>> .el-radio__input {
  padding: 0;
  display: none;
  width: 100%;
  margin-bottom: 0;
}

.math_option >>> .el-radio {
  width: 100%;
  margin-bottom: 0;
}
</style>
